import React from "react";
import "./socialMediaIcons.scss";

const SocialMediaIcons = ({ icons, title }) => {
  return (
    icons.length > 0 && (
      <div>
        {title && <h4 className="bullseye-red">{title}</h4>}
        <ul className="list-inline">
          {icons.map((icon) => {
            let cssStyle =
              icon.name === "yelp" ? "yelp-black-blue" : "black-blue";
            return (
              <li key={icon.name} className="list-inline-item social-icon">
                <a href={icon.url} rel="noopener noreferrer" target="_blank">
                  <img
                    className={"socialMedia__icon-md " + cssStyle}
                    src={icon.icon}
                    alt={icon.name}
                  />
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    )
  );
};

export default SocialMediaIcons;
