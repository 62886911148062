import { useEffect, useState } from "react";
import { getSearchLocation } from "../services/restSearchService";
import { FixRelativePath } from "../utils/utils";

/***** Attributes names using in the system ******/
const aboutPracticeName = "about practice";
const profilePhotoName = "profile photo";
const aboutDentistName = "about dentist";
const insuranceDescriptionName = "insurance description";
const paymentOptionsName = "payment options";

const getAttributeValue = (attributeCollection, attributeName, isImage) => {
  const attribute = attributeCollection.find(
    (attr) => attr.AttributeName.toLowerCase() === attributeName
  );

  if (attribute) {
    return isImage
      ? FixRelativePath(attribute.AttributeValue)
      : attribute.AttributeValue;
  }

  return "";
};

export function useLocation(locationId) {
  const [locationState, setLocationState] = useState({
    loading: true,
    location: {
      Id: 0,
      Name: "",
      Address1: "",
      Address4: "",
      StateAbbr: "",
      City: "",
      PhoneNumber: "",
      FacebookPageId: "",
      URL: "",
      Latitude: 0.0,
      Longitude: 0.0,
      Categories: [],
      RestAttributes: [],
      ImageFileUrl: "",
      RestLocationImage: [],
      RestLocationSocialMedia: {
        FacebookURL: "",
        InstagramURL: "",
        LinkedInURL: "",
        PinterestURL: "",
        TwitterURL: "",
        YelpURL: "",
      },

      aboutPractice: "",
      profilePhoto: "",
      aboutDentist: "",
      insuranceDescription: "",
      paymentOptions: "",

      servicesCollection: [],
      specialtyCollection: [],
      primarySpecialtyCollection: [],
    },
  });

  useEffect(() => {
    const getLocationAsync = async () => {
      const location = await getSearchLocation(locationId); // 26683746

      //validate that location have attributes
      if (location.RestAttributes.length > 0) {
        // Get Attributes
        location.profilePhoto = getAttributeValue(
          location.RestAttributes,
          profilePhotoName,
          true
        );

        location.aboutPractice = getAttributeValue(
          location.RestAttributes,
          aboutPracticeName
        );

        location.aboutDentist = getAttributeValue(
          location.RestAttributes,
          aboutDentistName
        );

        location.insuranceDescription = getAttributeValue(
          location.RestAttributes,
          insuranceDescriptionName
        );

        location.paymentOptions = getAttributeValue(
          location.RestAttributes,
          paymentOptionsName
        );
      }

      // Fix relative path for images
      location.ImageFileUrl = FixRelativePath(location.ImageFileUrl);

      setLocationState({ loading: false, location: location });
    };
    //console.log("Get location information");
    getLocationAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return locationState;
}
