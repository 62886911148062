import React from "react";

const PhotoListWithTitle = ({ title, items }) => {
  return (
    <div>
      <h4 className="bullseye-red">{title}</h4>
      {items && items.length && (
        <ul className="list-unstyled">
          {items.map((item) => (
            <li className="media py-3 border-bottom" key={item.id}>
              {item.image && (
                <img
                  className="mr-3"
                  src={item.image}
                  alt={item.body}
                  style={{ maxWidth: "30%" }}
                />
              )}
              <div className="media-body">
                {item.url ? (
                  <a href={item.url}>{item.body}</a>
                ) : (
                  <>{item.body}</>
                )}
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default PhotoListWithTitle;
