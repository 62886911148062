import "./App.scss";
import BusinessHours from "./components/businessHours";
import CardWithImage from "./components/cardWithImage";
import GoogleMap from "./components/googleMap";
import HeaderDenmat from "./components/headerDenmat";
import LocationInformation from "./components/locationInformation";
import MultiCarousel from "./components/multiCarousel";
import Showcase from "./components/showcase";
import SimpleListWithTitle from "./components/simpleListWithTitle";
import SocialMediaIcons from "./components/socialMediaIcons";
import Testimonials from "./components/testimonials";
import PhotoListWithTitle from "./components/photoListWithTitle";

import {
  daysArray,
  fakeList1,
  benefits,
  fakeList2,
  fakeImages,
  mapCoordenates,
  socialMediaIcons,
  testimonials,
  documents,
  promotions,
} from "../src/services/json/sampledata";

function App() {
  const documentIcon = (
    <svg className="mr-2" width="16" height="16">
      <use xlinkHref="/images/b-icons/bootstrap-icons.svg#file-earmark-text-fill" />
    </svg>
  );
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <HeaderDenmat title="Doctor Name, DDS" subTitle="Primary Specialty" />
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-4">
          <div className="card shadow-sm p-3 mt-3">
            <div className="w-100 mb-2" style={{ height: 255 }}>
              <GoogleMap info={mapCoordenates} />
            </div>
            <LocationInformation
              address1="3121 US Highway 22"
              city="Branchburg"
              stateAbbr="NJ"
              postalCode="08876"
              country="United State"
              locationName="Electric Vine Inc"
              phone="(201) 123-5645"
              contactName="Joshua Rich"
              contactPosition="President and CEO"
            />
            <div className="mt-3">
              <SocialMediaIcons icons={socialMediaIcons} />
            </div>
          </div>
          <div className="card shadow-sm p-3 mt-3">
            <SimpleListWithTitle title="Service" items={fakeList1} />
          </div>
          <div className="card shadow-sm p-3 mt-3">
            <BusinessHours daysArray={daysArray} isClose={false} />
          </div>
          <div className="card shadow-sm p-3 mt-3">
            <SimpleListWithTitle title="Certifications" items={fakeList2} />
          </div>
          <div className="card shadow-sm p-3 mt-3">
            <SimpleListWithTitle
              title="Documents"
              items={documents}
              icon={documentIcon}
            />
          </div>
          <div className="card shadow-sm p-3 mt-3">
            <PhotoListWithTitle title="Promotions" items={promotions} />
          </div>
        </div>
        <div className="col-12 col-md-8">
          <div className="card shadow-sm p-3 mt-3">
            <CardWithImage
              body="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
              title="About [Location Name]"
              image="http://picsum.photos/400/300"
            />
          </div>
          <div className="card shadow-sm p-3 mt-3">
            <SimpleListWithTitle
              title="Service"
              items={fakeList1}
              numberOfColumns={3}
            />
          </div>
          <div className="card shadow-sm p-3 mt-3">
            <MultiCarousel
              items={fakeImages}
              title="Super images"
              itemNumber={1}
              containerExtraClass="m-2"
            />
          </div>
          <div className="card shadow-sm p-3 mt-3">
            <Showcase title="Benefits" items={benefits} />
          </div>
          <div className="card shadow-sm p-3 mt-3">
            <Testimonials title="Testimonials" testimonials={testimonials} />
          </div>
        </div>
      </div>
      <div className="row">
        <footer className="col-12 m-4"></footer>
      </div>
    </div>
  );
}

export default App;
