import React, { useEffect, useState } from "react";
import "google-maps-reviews/google-maps-reviews.css";
import Reviews from "../reviews";
import PropTypes from "prop-types";

const GoogleReviews = ({ GoogleplaceId }) => {
  const [reviews, setReviews] = useState({
    rating: 5,
    countReviews: 0,
    reviews: [],
    status: false,
    urlMoreReviews: "#",
  });

  var callback = function (place, status) {
    // eslint-disable-next-line no-undef
    if (status === google.maps.places.PlacesServiceStatus.OK) {
      const reviews = {
        rating: place.rating,
        countReviews: place.reviews.length,
        reviews: place.reviews,
        status: true,
        urlMoreReviews: place.url,
      };

      setReviews(reviews);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      // initiate a Google Places Object
      const mydiv = document.getElementById("bu-google-reviews");

      // eslint-disable-next-line no-undef
      var service = new google.maps.places.PlacesService(mydiv);

      const request = {
        placeId: GoogleplaceId,
      };

      service.getDetails(request, callback);
    }, 2000);
  }, [GoogleplaceId]);

  return (
    <>
      <div id="bu-google-reviews" className="d-none"></div>
      {reviews.status === true && (
        <Reviews title="Reviews" listReviews={reviews} />
      )}
    </>
  );
};
GoogleReviews.propTypes = {
  /** Google Place Id for the location */
  GoogleplaceId: PropTypes.string.isRequired,
};
export default GoogleReviews;
