import http from "./httpService";

const endpointPath = "RestSearch.svc";
const categoryGetMethod = "GetCategories";
const locationGetMethod = "GetLocation";

function categoryUrl() {
  return `${endpointPath}/${categoryGetMethod}?ReturnAllCategories=true`;
}
function locationUrl(id) {
  return `${endpointPath}/${locationGetMethod}?LocationId=${id}`;
}

export async function getCategories() {
  http.setSecurityParameters();

  const categories = await http.get(categoryUrl());
  return categories.data;
}

export async function getSearchLocation(id) {
  http.setSecurityParameters();

  const location = await http.get(locationUrl(id));
  return location.data;
}
