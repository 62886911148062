import React from "react";
import CardRating from "./cardRating";
import { NewId } from "../../utils/utils";

const CardListRating = ({ listRatings }) => {
  return (
    <div className="my-10">
      {listRatings &&
        listRatings.map((profile) => <CardRating key={NewId()} {...profile} />)}
    </div>
  );
};

export default CardListRating;
