import React from "react";
import PropTypes from "prop-types";

/** Component to display a list of business and holiday hours
 *  in a table also this component could behave as a button with
 *  a popup implementation depending on the props
 */
const BusinessHours = ({ daysArray, openCloseLabel, isClose }) => {
  let alertText = isClose ? "Close Now" : "Open Now";
  // If a custom openClose label is specify that will override the default value of the alert
  alertText = !openCloseLabel ? alertText : openCloseLabel;

  return (
    <div>
      <h4 className="bullseye-red">Hours</h4>
      <small>Hours may vary by location</small>
      <div className="alert alert-secondary my-1" role="alert">
        <span
          className={
            isClose
              ? "font-weight-bold bullseye-red"
              : "font-weight-bold bullseye-green"
          }
        >
          {alertText}
        </span>
      </div>
      <table className="w-100 m-2">
        <tbody>
          {daysArray.map((dayInfo) => {
            return (
              <tr key={dayInfo.day}>
                <th scope="row">{dayInfo.day}</th>
                <td>{dayInfo.hours}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

BusinessHours.propTypes = {
  /** Days of the week*/
  daysArray: PropTypes.arrayOf(
    PropTypes.shape({
      /** name of the day of the week */
      day: PropTypes.string,

      /** hours in format 08:00 AM - 05:00 PM*/
      hours: PropTypes.string,
    })
  ).isRequired,

  /** Is Close indicates the control the location is
   *  close and change the color of the text to red if
   *  not close the color will be green */
  isClose: PropTypes.bool,

  /** Open or Close label for Subtitle*/
  openCloseLabel: PropTypes.string,
};

BusinessHours.defaultProps = {
  /** Default value for isClose flag is false */
  isClose: false,
};

export default BusinessHours;
