import React from "react";

const Testimonials = ({ title, testimonials }) => {
  return (
    <div>
      {title && <h4 className="bullseye-red">{title}</h4>}
      {testimonials && testimonials.length > 0 && (
        <div className="px-2 mx-2">
          {testimonials.map((testimonial) => {
            return (
              <blockquote key={testimonial.id} className="blockquote my-5">
                <p className="mb-0">{testimonial.description}</p>
                <footer className="blockquote-footer text-right">
                  {testimonial.author}
                </footer>
              </blockquote>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Testimonials;
