import GoogleMapReact from "google-map-react";
import React from "react";
import GoogleMapMarker from "./googleMapMarker";
import globalConfigProp from "../../config.json";

const GoogleMap = ({ info, onMapReady }) => {
  let position = {
    center: {
      lat: info.latitude,
      lng: info.longitude,
    },
    zoom: 17,
  };
  // Return map bounds based on list of places
  const getMapBounds = (map, maps, info) => {
    const bounds = new maps.LatLngBounds();

    bounds.extend(new maps.LatLng(info.latitude, info.longitude));

    return bounds;
  };
  // Re-center map when resizing the window
  const bindResizeListener = (map, maps, bounds) => {
    maps.event.addDomListenerOnce(map, "idle", () => {
      maps.event.addDomListener(window, "resize", () => {
        map.fitBounds(bounds);
      });
    });
  };
  // Fit map to its bounds after the api is loaded
  const apiIsLoaded = (map, maps, info, isLoaded = false) => {
    if (onMapReady) {
      onMapReady(map, maps, info);
    }

    // Get bounds by our places
    const bounds = getMapBounds(map, maps, info);
    // Fit map to bounds
    // map.fitBounds(bounds);
    // Bind the resize listener
    bindResizeListener(map, maps, bounds);
  };
  return (
    position.center.lat > 0 && (
      <GoogleMapReact
        bootstrapURLKeys={{
          key: globalConfigProp.GoogleMapKey,
          libraries: ["places"],
        }}
        defaultCenter={position.center}
        defaultZoom={position.zoom}
        onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps, info)}
      >
        <GoogleMapMarker lat={position.center.lat} lng={position.center.lng} />
      </GoogleMapReact>
    )
  );
};

export default GoogleMap;
