import { useEffect, useState } from "react";
import { getCategories } from "../services/restSearchService";

/***** Category names using in the system ******/
const servicesName = "services";
const specialtyName = "specialty";
const primarySpecialtyName = "primary specialty";

export function useCategories() {
  const [categoriesState, setCategoriesState] = useState({
    categories: [],
    servicesId: 0,
    specialtyId: 0,
    primarySpecialtyId: 0,
    serviceCollection: [],
    specialtyCollection: [],
    primarySpecialtyCollection: [],
  });

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    try {
      const getCategoriesStateAsync = async () => {
        // Get all categories in the system
        //console.log("Get categories async");
        const categories = await getCategories();
        //console.log(categories);
        // category collections
        const serviceCollection = [];
        const specialtyCollection = [];
        const primarySpecialtyCollection = [];

        // find category Services by Name
        const servicesCategory = categories.find(
          (cat) =>
            cat.CategoryName.toLowerCase() === servicesName && cat.Depth === 0
        );

        // find category Specialty by Name
        const specialtyCategory = categories.find(
          (cat) =>
            cat.CategoryName.toLowerCase() === specialtyName && cat.Depth === 0
        );

        // find category Primary Specialty by Name
        const primarySpecialtyCategory = categories.find(
          (cat) =>
            cat.CategoryName.toLowerCase() === primarySpecialtyName &&
            cat.Depth === 0
        );

        categories.forEach((category) => {
          // Populate Service Collection
          if (category.ParentCategoryId === servicesCategory.CategoryId) {
            serviceCollection.push({
              id: category.CategoryId,
              name: category.CategoryName,
            });
          }

          // Populate Specialty Collection
          if (category.ParentCategoryId === specialtyCategory.CategoryId) {
            specialtyCollection.push({
              id: category.CategoryId,
              name: category.CategoryName,
            });
          }

          // Populate Primary Specialty Collection
          if (
            category.ParentCategoryId === primarySpecialtyCategory.CategoryId
          ) {
            primarySpecialtyCollection.push({
              id: category.CategoryId,
              name: category.CategoryName,
            });
          }
        });

        setCategoriesState({
          categories: categories,
          servicesId: servicesCategory.CategoryId,
          specialtyId: specialtyCategory.CategoryId,
          primarySpecialtyId: primarySpecialtyCategory.CategoryId,
          serviceCollection: serviceCollection,
          specialtyCollection: specialtyCollection,
          primarySpecialtyCollection: primarySpecialtyCollection,
        });

        setIsLoading(false);
      };

      getCategoriesStateAsync();
    } catch (error) {
      setError(error);
    }
    return () => {
      console.log("Component did unmount");
    };
  }, []);
  return { categoriesState, isLoading, error };
}
