import React from "react";
import PropTypes from "prop-types";
import "./showcase.scss";

/**
 * Showcase Card with an icon on the top, a title below and paragraph
 */
const ShowcaseCard = ({ icon, title, body }) => {
  return (
    <div className="m-1 showcase__card--maxSize">
      <img
        className="rounded-circle my-3 mx-auto d-block"
        src={icon}
        alt={title}
      />
      <strong>{title}</strong>
      <p>{body}</p>
    </div>
  );
};

ShowcaseCard.propTypes = {
  /** icon URL */
  icon: PropTypes.string,
  /** title */
  title: PropTypes.string.isRequired,
  /** description in a paragraph */
  body: PropTypes.string.isRequired,
};

export default ShowcaseCard;
