import React from "react";
import PropTypes from "prop-types";
import ShowcaseCard from "./showcaseCard";

const Showcase = ({ title, items }) => {
  return (
    <div>
      <h4 className="bullseye-red">{title}</h4>
      <div className="d-flex align-content-around flex-wrap">
        {items &&
          items.length > 0 &&
          items.map((item) => (
            <ShowcaseCard
              key={item.id}
              title={item.title}
              icon={item.icon}
              body={item.body}
            />
          ))}
      </div>
    </div>
  );
};

Showcase.propTypes = {
  /** Title for the card */
  title: PropTypes.string.isRequired,
  /** Array of Items to display in columns */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      /** icon URL */
      icon: PropTypes.string,
      /** title */
      title: PropTypes.string.isRequired,
      /** description in a paragraph */
      body: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default Showcase;
