import React from "react";
import { Route, Switch } from "react-router-dom";
import "./App.scss";

import { ToastContainer } from "react-toastify";
import StandardLocator from "./components/pages/standardLocator";
import App from "./App";
import DenmatLandingPage from "./components/pages/denmatLandingPage";
import { HelmetProvider } from "react-helmet-async";
function App2() {
  console.log("app start....");
  return (
    <>
      <HelmetProvider>
        <ToastContainer />
        <Switch>
          <Route
            path="/:country([A-Za-z]{2})/:state([A-Za-z]{2})/:city/:slug?"
            component={DenmatLandingPage}
          />
          <Route path="/test" component={App} />
          <Route path="/" component={StandardLocator} />
        </Switch>
      </HelmetProvider>
    </>
  );
}

export default App2;
