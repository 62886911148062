import React, { useEffect } from "react";

const standardInterfaceURL = process.env.REACT_APP_INTERFACE_URL;
const domain = process.env.REACT_APP_BULLSEYE_DOMAIN;

const StandardLocator = () => {
  useEffect(() => {
    var bullseyelistener = function (e) {
      var regex = new RegExp("^.*\\." + domain + "\\.com.*$", "i");
      var iFrame = document.getElementById("bullseye_iframe");

      if (regex.test(e.origin) && Number.isInteger(e.data)) {
        iFrame.height = Math.round(e.data);
      }
    };
    if (window.addEventListener) {
      window.addEventListener("message", bullseyelistener, false);
    } else {
      window.attachEvent("onmessage", bullseyelistener);
    }

    setTimeout(() => {
      document
        .querySelectorAll(".ckListCat li label")
        .forEach(
          (x) =>
            (x.innerHTML =
              "<span class='checkmark checkmarkBlack'>" +
              x.innerHTML +
              "</span>")
        );
      document
        .querySelectorAll(".ckListCat li label")
        .forEach((x) => x.prepend(x.previousSibling));
    }, 1000);
  }, []);

  const searchParameter = window.location.search ? window.location.search : "";

  return (
    <div className="container-fluid">
      <iframe
        id="bullseye_iframe"
        title="Bullseye Locations Search12"
        allowFullScreen
        src={`${standardInterfaceURL}${searchParameter}`}
        width="100%"
        height="1000"
        style={{ border: 0 }}
        scrolling="auto"
        onLoad={() => {
          window.parent.scrollTo(0, 0);
        }}
        allow="geolocation"
      ></iframe>
    </div>
  );
};

export default StandardLocator;
