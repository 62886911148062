import React from "react";
import StartRating from "./startRating";
import CardListRating from "./cardListRating";
import "./reviews.scss";
import PropTypes from "prop-types";

/** Component to display the information of reviews */
const Reviews = ({ title, listReviews }) => {
  const { rating, countReviews, reviews, urlMoreReviews } = listReviews;

  return (
    <>
      <div>
        <h4 className="d-block bullseye-red">{title}</h4>

        <div className="textHeader mb-1">
          <span className="align-middle d-inline-block">
            <StartRating rating={rating} isTittle={true} />
          </span>
          <span className="align-middle mx-2 px-2">
            <strong>Avg. {rating}</strong>
          </span>
          <span className="align-middle mx-2 px-2 text-muted border-left border-right">
            {countReviews} reviews
          </span>
        </div>
        <CardListRating listRatings={reviews} />
        <div className="my-2">
          <a target="_blank" rel="noreferrer" href={urlMoreReviews}>
            Read More Reviews
          </a>
        </div>
      </div>
    </>
  );
};
Reviews.propTypes = {
  /** Title to display on the header component */
  title: PropTypes.string.isRequired,
  listReviews: PropTypes.shape({
    /** AVG rating number of the review */
    rating: PropTypes.number.isRequired,
    /** count of the reviews */
    countReviews: PropTypes.number.isRequired,
    /** url external to show more reviews */
    urlMoreReviews: PropTypes.string.isRequired,
    /** List of reviews */
    reviews: PropTypes.arrayOf(
      PropTypes.shape({
        /** Name of the person who made the review */
        author_name: PropTypes.string.isRequired,
        /** rating of the review */
        rating: PropTypes.number.isRequired,
        /** description of the time when was made the review */
        relative_time_description: PropTypes.string.isRequired,
        /** description text of the review */
        text: PropTypes.string.isRequired,
        /** List of ratings services if there was a list  */
        services: PropTypes.arrayOf(
          PropTypes.shape({
            /** name of the list to rate */
            name: PropTypes.string.isRequired,
            /** rating of this list */
            rating: PropTypes.number.isRequired,
          })
        ),
      })
    ),
  }).isRequired,
};
export default Reviews;
