import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { useCategories } from "../../store/category.state";
import { useLocation } from "../../store/location.state";
import HeaderDenmat from "../headerDenmat/headerDenmat";
import { Helmet } from "react-helmet-async";

import CardWithImage from "../cardWithImage";
import GoogleMap from "../googleMap";
import LocationInformation from "../locationInformation";
import MultiCarousel from "../multiCarousel";
import SimpleListWithTitle from "../simpleListWithTitle";
import SocialMediaIcons from "../socialMediaIcons";
import Payment from "../payment";
import GoogleReviews from "../googleReviews";
import "./denmatLandingPage.scss";

import {
  getStrListSplitByComma,
  getListCategoryFromCollectionByExistingCategories,
  NewId,
  getLocationSocialMedia,
} from "../../utils/utils";

const DenmatLandingPage = (props) => {
  const slug = props.match.params.slug;

  useEffect(() => {
    let body = document.body,
      html = document.documentElement;

    let height = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );

    window.parent.postMessage(height + 20, "*");
  }, []);

  // slug = resource-4-floors-fort-lauderdale-FL-5771864
  const locationId = parseInt(slug.substr(slug.lastIndexOf("-") + 1));
  const { categoriesState, error, isLoading } = useCategories();
  const [googleMapIsReady, setGoogleMapIsReady] = useState(false);

  if (error) console.log(error);

  //init location state
  const locationState = useLocation(locationId);

  // Get array of id of all existing categories in the location
  let existingCategories = [];

  if (!locationState.loading && locationState.location.Categories.length > 0) {
    existingCategories = locationState.location.Categories.map(
      (category) => category.CategoryId
    );
  }
  //const isStateLoaded = () => !locationState.loading && !isLoading;
  const { location } = locationState;

  //console.log("location", location);

  const mapCoordenates = {
    latitude: location.Latitude,
    longitude: location.Longitude,
    address1: location.Name,
  };
  /** Get Service list from categories collection */
  const servicesList = getListCategoryFromCollectionByExistingCategories(
    categoriesState.serviceCollection,
    existingCategories
  );
  /** Get Primary Specialty list from categories  collection*/
  const primarySpecialtyList = getListCategoryFromCollectionByExistingCategories(
    categoriesState.primarySpecialtyCollection,
    existingCategories
  );
  /** Get Specialty list from categories  collection */
  const specialtyList = getListCategoryFromCollectionByExistingCategories(
    categoriesState.specialtyCollection,
    existingCategories
  );

  /** Convert to String the list of primary specialty seprated by comma */
  const primarySpecialtyStr = getStrListSplitByComma(primarySpecialtyList);

  /** Get the list of the images for the carrousel */
  const imagesLocation = [];

  if (location.RestLocationImage && location.RestLocationImage.length > 0)
    location.RestLocationImage.forEach((item) => {
      imagesLocation.push({
        id: NewId(),
        image: item.ImageFileUrl,
        titleImage: location.name,
      });
    });
  /** Get the list of the social media icons for this location */
  const socialIcons = getLocationSocialMedia(location);

  return (
    <div className="container">
      <ToastContainer />
      {isLoading && <div>Loading...</div>}
      {!isLoading && location.RestLocationSEO && (
        <>
          <Helmet>
            <title>
              {location.RestLocationSEO.SEOTitle || "DenMat-" + location.Name}
            </title>
            <meta
              name="description"
              content={location.RestLocationSEO.MetaDescription}
            />
          </Helmet>
        </>
      )}

      <div className="row sticky">
        <div className="col-12">
          <HeaderDenmat
            title={location.Name}
            subTitle={primarySpecialtyStr}
            locationId={location.Id}
            locationLogo={location.ImageFileUrl}
            searchUrlParams={props.location.search}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-4">
          {location && (
            <div className="card shadow-sm p-3 mt-3">
              <div className="w-100 mb-2" style={{ height: 200 }}>
                <GoogleMap
                  info={mapCoordenates}
                  onMapReady={() => setGoogleMapIsReady(true)}
                />
              </div>
              <LocationInformation
                address1={location.Address1}
                address2={location.Address2}
                city={location.City}
                stateAbbr={location.StateAbbr}
                postalCode={location.PostCode}
                country={location.CountryName}
                locationName={location.Name}
                practiceName={location.Address4}
                phone={location.PhoneNumber}
              />
              {socialIcons && (
                <div className="mt-2">
                  <SocialMediaIcons icons={socialIcons} />
                </div>
              )}
            </div>
          )}
          {servicesList && servicesList.length > 0 && (
            <div className="card shadow-sm p-3 mt-3 mb-1 ">
              <SimpleListWithTitle
                title="Services"
                items={servicesList}
                numberOfColumns={1}
              />
            </div>
          )}
          {specialtyList && specialtyList.length > 0 && (
            <div className="card shadow-sm p-3 mt-3 mb-1">
              <SimpleListWithTitle
                title={specialtyList.length > 1 ? "Specialties" : "Specialty"}
                items={specialtyList}
                numberOfColumns={1}
              />
            </div>
          )}
        </div>

        {(location.aboutPractice ||
          imagesLocation.length > 0 ||
          (location.Name && location.aboutDentist)) && (
          <div className="col-12 col-md-8">
            {location.aboutPractice && (
              <div className="card shadow-sm p-3 mt-3">
                <CardWithImage
                  body={location.aboutPractice}
                  title={
                    "About " +
                    (location.Address4 ? location.Address4 : "Practice")
                  }
                  image=""
                />
              </div>
            )}
            {imagesLocation.length > 0 && (
              <div className="card shadow-sm p-2 mt-3">
                <MultiCarousel
                  items={imagesLocation}
                  title=""
                  itemNumber={1}
                  containerExtraClass="m-2"
                />
              </div>
            )}
            {location.Name && location.aboutDentist && (
              <div className="card shadow-sm p-3 mt-3">
                <CardWithImage
                  body={location.aboutDentist}
                  title={"About " + location.Name}
                  image={location.ImageFileUrl}
                />
              </div>
            )}
          </div>
        )}
        {location.insuranceDescription && (
          <div className="col-12">
            <div className="card shadow-sm p-3 mt-3">
              <Payment insurance={location.insuranceDescription} />
            </div>
          </div>
        )}
        {location.paymentOptions && (
          <div className="col-12">
            <div className="card shadow-sm p-3 mt-3">
              <Payment paymantOptions={location.paymentOptions} />
            </div>
          </div>
        )}

        {location.GooglePlaceID !== undefined &&
          location.GooglePlaceID !== "" &&
          googleMapIsReady && (
            <div className="col-12">
              <div className="card shadow-sm p-3 mt-3">
                <GoogleReviews GoogleplaceId={location.GooglePlaceID} />
              </div>
            </div>
          )}
      </div>
    </div>
  );
};

export default DenmatLandingPage;
