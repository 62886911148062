import { Button, Modal } from "react-bootstrap";
import React, { useState } from "react";
import "./leadForm.scss";

const leadManagerFormURL = process.env.REACT_APP_LEAD_FORM_URL;
const leadManagerFormID = process.env.REACT_APP_LEAD_FORM_ID;

const LeadForm = ({
  buttonTitle,
  locationId,
  modalTitle,
  locationLogo,
  isButtonLg = true,
}) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <button
        className={"btn btn-" + (isButtonLg ? "lg" : "xs") + " btn-primary"}
        onClick={handleShow}
      >
        <span className="d-block">{buttonTitle}</span>
      </button>
      <Modal show={show} onHide={handleClose} dialogClassName="modal-100w">
        <Modal.Header className="modal-header-custom">
          <div className="w-100 mb-4">
            <img
              src="/images/denmat-logo.png"
              className="float-left"
              alt="DenMat Logo"
              style={{ maxWidth: 100 }}
            ></img>
            {locationLogo && (
              <img
                src={locationLogo}
                className="float-right"
                alt="DenMat Logo"
                style={{ maxWidth: 250, maxHeight: 80 }}
              ></img>
            )}
            <br />
            <span className="w-25 modal-title h3"></span>
          </div>
          <Modal.Title className="w-25"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <iframe
            className="loader-image"
            style={{ border: 0 }}
            title="Lead Form"
            src={
              leadManagerFormURL +
              "?ID=" +
              leadManagerFormID +
              "&LocationID=" +
              locationId
            }
            width="100%"
            height="535"
          ></iframe>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default LeadForm;
