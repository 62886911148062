import React from "react";
import Address from "../address/address";

const LocationInformation = ({
  address1,
  address2,
  city,
  stateAbbr,
  postalCode,
  country,
  phone,
  locationName,
  contactName,
  practiceName,
  contactPosition,
  latitude,
  longitude,
}) => {
  return (
    <div>
      <h5>{locationName}</h5>
      <h6>{practiceName}</h6>
      {address1 && postalCode && (
        <Address
          address1={address1}
          address2={address2}
          city={city}
          stateAbbr={stateAbbr}
          postalCode={postalCode}
          country={country}
          phone={phone}
        ></Address>
      )}

      {contactName && (
        <span>
          <b>Contact</b> {contactName}{" "}
          {contactPosition && <>, {contactPosition}</>}
        </span>
      )}
    </div>
  );
};

export default LocationInformation;
