import _ from "lodash";
import admins from "../config.json";

const bullseyeAdminURL = process.env.REACT_APP_ADMIN_URL;

const getUrlParameterValue = (sParam) => {
  var sPageURL = window.location.search.substring(1),
    sURLVariables = sPageURL.split("&"),
    sParameterName,
    i;

  for (i = 0; i < sURLVariables.length; i++) {
    sParameterName = sURLVariables[i].split("=");

    if (sParameterName[0] === sParam) {
      return typeof sParameterName[1] === undefined
        ? true
        : decodeURIComponent(sParameterName[1]);
    }
  }
  return false;
};

const NewId = function () {
  // Math.random should be unique because of its seeding algorithm.
  // Convert it to base 36 (numbers + letters), and grab the first 9 characters
  // after the decimal.
  return "_" + Math.random().toString(36).substr(2, 9);
};

const FixRelativePath = function (imagePath) {
  if (!imagePath) return imagePath;

  let returnPath = imagePath;

  if (imagePath.toLowerCase().includes("~/resources/")) {
    returnPath = imagePath
      .toLowerCase()
      .replace("~/resources/", bullseyeAdminURL + "resources/");
  }

  // I did this because sometimes the server returns encode url and if I only apply the encodeURI
  // sometimes the urls are double encode
  return encodeURI(decodeURI(returnPath));
};

const splitListByColumns = function (
  originalList,
  numberOfColumns,
  screenSize
) {
  let numberColumns;
  let lists = [];

  switch (screenSize) {
    case "sm":
      numberColumns = originalList.length;
      break;
    case "md":
      numberColumns =
        numberOfColumns > 1 ? originalList.length / 2 : originalList.length;
      break;
    case "lg":
      numberColumns = originalList.length / numberOfColumns;
      break;

    default:
      numberColumns = originalList.length / numberOfColumns;
      break;
  }
  lists = _.chunk(originalList, Math.ceil(numberColumns));

  return lists;
};

const getStrListSplitByComma = function (originalList) {
  let strSplit = "";

  originalList.forEach((e) => {
    if (strSplit !== "") strSplit += ", ";
    strSplit += e.title;
  });
  return strSplit;
};
/** Get a category list from the collection of the JSON,  */
const getListCategoryFromCollectionByExistingCategories = function (
  collection,
  existingCategories
) {
  let returnList = [];
  collection
    .filter((e) => {
      return existingCategories.includes(e.id);
    })
    .forEach((item) => {
      returnList.push({ id: item.id, title: item.name });
    });

  return returnList;
};

function getLocationSocialMedia(contactInformation) {
  const socialMediaInfo = contactInformation.RestLocationSocialMedia;
  const icons = [];

  if (!socialMediaInfo) return icons;

  if (socialMediaInfo.InstagramURL) {
    icons.push({
      name: "instagram",
      icon: "/images/instagram.svg",
      url: admins.instagramMainUrl + socialMediaInfo.InstagramURL,
    });
  }

  if (socialMediaInfo.LinkedInURL) {
    icons.push({
      name: "linkedin",
      icon: "/images/linkedin.svg",
      url: admins.LinkedInMainUrl + socialMediaInfo.LinkedInURL,
    });
  }

  if (contactInformation.FacebookPageId) {
    icons.push({
      name: "facebook",
      icon: "/images/facebook.svg",
      url: admins.facebookMainUrl + contactInformation.FacebookPageId,
    });
  }

  if (socialMediaInfo.TwitterURL) {
    icons.push({
      name: "twitter",
      icon: "/images/twitter.svg",
      url: admins.TwitterMainUrl + socialMediaInfo.TwitterURL,
    });
  }

  if (socialMediaInfo.PinterestURL) {
    icons.push({
      name: "pinterest",
      icon: "/images/pinterest.svg",
      url: admins.PinterestMainUrl + socialMediaInfo.PinterestURL,
    });
  }

  if (socialMediaInfo.YelpURL) {
    icons.push({
      name: "yelp",
      icon: "/images/yelp.svg",
      url: admins.YelpMainUrl + socialMediaInfo.YelpURL,
    });
  }
  return icons;
}

export {
  NewId,
  FixRelativePath,
  splitListByColumns,
  getStrListSplitByComma,
  getListCategoryFromCollectionByExistingCategories,
  getLocationSocialMedia,
  getUrlParameterValue,
};
