import React, { useState, useEffect } from "react";
import "./headerDenmat.scss";
import LeadForm from "../leadForm";
import { Breadcrumb } from "react-bootstrap";
import { getUrlParameterValue } from "../../utils/utils";

const HeaderDenmat = ({
  title,
  subTitle,
  locationId,
  imageFileUrl,
  searchUrlParams = "",
}) => {
  const [isSmall, setIsSmall] = useState(false);
  const [cssStyle, setCssStyle] = useState("headerDenmat__main");
  const [prevScrollPos, setPrevScrollPos] = useState(0);

  const debounce = (func, wait, immediate) => {
    var timeout;
    return function () {
      var context = this,
        args = arguments;
      var later = function () {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  };

  const handleScroll = debounce((e) => {
    e.preventDefault();
    const currentScrollPos = window.pageYOffset;
    const status = currentScrollPos >= 100;

    if (prevScrollPos !== currentScrollPos && status !== isSmall) {
      setPrevScrollPos(currentScrollPos);
      setIsSmall(status);
      setCssStyle(status ? "headerDenmat__main small" : "headerDenmat__main");
    }
  }, 5000);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, false);

    return () => window.removeEventListener("scroll", handleScroll, false);
  });

  const urlBack = getUrlParameterValue("urlR");
  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item
          href={`${
            urlBack && urlBack !== false
              ? urlBack
              : process.env.REACT_APP_PUBLIC_DENMAT_LOCATOR
          }${searchUrlParams}`}
        >
          Find a Dentist
        </Breadcrumb.Item>
        <Breadcrumb.Item active={true}>{title}</Breadcrumb.Item>
      </Breadcrumb>

      <header
        className={`${cssStyle} p-3 shadow-sm mt-n3 d-flex justify-content-between`}
      >
        <div>
          <h1>{title}</h1>
          <h2 style={{ fontWeight: 300 }}>{subTitle}</h2>
        </div>
        <div className="align-self-center">
          <LeadForm
            modalTitle="Contact Us"
            buttonTitle="Contact Us"
            locationId={locationId}
            locationLogo={imageFileUrl}
            isButtonLg={!isSmall}
          ></LeadForm>
        </div>
      </header>
    </>
  );
};

export default HeaderDenmat;
