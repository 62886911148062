import React from "react";
import PropTypes from "prop-types";

/** Component to display some location address including the country and phone
 *  TODO: support for different addres format city, state postal code for different
 *  countries other than USA
 */
const Address = ({
  address1,
  address2,
  city,
  stateAbbr,
  postalCode,
  country,
  phone,
}) => {
  const phoneIcon = (
    <svg className="mr-2" width="16" height="16">
      <use xlinkHref="/images/b-icons/bootstrap-icons.svg#telephone-outbound-fill" />
    </svg>
  );

  return (
    <ul className="list-unstyled">
      <li>{address1}</li>
      <li>{address2}</li>
      <li>
        {city}, {stateAbbr} {postalCode}
      </li>
      <li>{country}</li>
      {phone && (
        <li>
          <a href={"tel:" + phone}>
            {phoneIcon}
            {phone}
          </a>
        </li>
      )}
    </ul>
  );
};

Address.propTypes = {
  address1: PropTypes.string.isRequired,
  address2: PropTypes.string,
  city: PropTypes.string,
  /** Two letter state or province abbreviation */
  stateAbbr: PropTypes.string,
  postalCode: PropTypes.string.isRequired,
  country: PropTypes.string,
  /** If this prop is present the phone will show as link with tel: */
  phone: PropTypes.string,
};

export default Address;
