import { NewId } from "../../utils/utils";

const fakeReviews = {
  countReviews: 5,
  rating: 5,
  reviews: [
    {
      author_name: "Dennis Suler",
      rating: 5,
      relative_time_description: "5 months ago",
      text:
        "Great staff, great dentist. He's not only a good dentist (I was referred by my Orthodontist who goes to him - says that he has the best hands in the business), he's a good person.",
      time: 1599665333,
    },
    {
      author_name: "Vic",
      rating: 3,
      relative_time_description: "5 months ago",
      text:
        "Great staff, great dentist. He's not only a good dentist (I was referred by my Orthodontist who goes to him - says that he has the best hands in the business), he's a good person.",
      time: 1599665333,
      services: [
        { name: "professionalism", rating: 4 },
        { name: "service", rating: 3 },
        { name: "Product Knowledge", rating: 5 },
      ],
    },
    {
      author_name: "David",
      rating: 4,
      relative_time_description: "5 months ago",
      text:
        "Great staff, great dentist. He's not only a good dentist (I was referred by my Orthodontist who goes to him - says that he has the best hands in the business), he's a good person.",
      time: 1599665333,
      services: [
        { name: "professionalism", rating: 4 },
        { name: "service", rating: 3 },
        { name: "Product Knowledge", rating: 5 },
      ],
    },
  ],
  status: true,
  urlMoreReviews: "https://maps.google.com/?cid=507457156565060969",
};

const daysArray = [
  { day: "Wed (today)", hours: "9:00 AM - 8:00 PM" },
  { day: "Thu", hours: "9:00 AM - 8:00 PM" },
  { day: "Fri", hours: "9:00 AM - 8:00 PM" },
  { day: "Sat", hours: "9:00 AM - 8:00 PM" },
  { day: "Sun", hours: "9:00 AM - 8:00 PM" },
  { day: "Mon (Labor Day)", hours: "9:00 AM - 8:00 PM" },
  { day: "Tue", hours: "9:00 AM - 8:00 PM" },
];
const fakeList1 = [
  { id: 1, title: "Service 1" },
  { id: 2, title: "Service 2" },
  { id: 3, title: "Service 3" },
  { id: 4, title: "Service 4" },
  { id: 5, title: "Service 5" },
];
const fakeList2 = [
  { id: 1, title: "Certifications 1" },
  { id: 2, title: "Certifications 2" },
  { id: 3, title: "Certifications 3" },
  { id: 4, title: "Certifications 4" },
];
const documents = [
  { id: 1, title: "Industry Report", url: "#" },
  { id: 2, title: "Home Improvent Guide", url: "#" },
  { id: 3, title: "Quarantine DYI Guide", url: "#" },
];
const benefits = [
  {
    id: 1,
    icon: "https://via.placeholder.com/150",
    title: "Fully ABC-Certified Profesional",
    body:
      "is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
  },
  {
    id: 2,
    icon: "https://via.placeholder.com/150",
    title: "Satisfaction and Top-Tier Performance Guaranteed",
    body:
      "is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
  },
  {
    id: 3,
    icon: "https://via.placeholder.com/150",
    title: "Award-Winning",
    body:
      "is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
  },
];
const fakeImages = [
  {
    id: NewId(),
    image: "https://picsum.photos/800/400",
    titleImage: "Fake Image",
  },
  {
    id: NewId(),
    image: "https://picsum.photos/800/401",
    titleImage: "Fake Image",
  },
  {
    id: NewId(),
    image: "https://picsum.photos/801/400",
    titleImage: "Fake Image",
  },
];
const mapCoordenates = {
  latitude: 40.712776,
  longitude: -74.005974,
  address1: "New York",
};
const socialMediaIcons = [
  {
    name: "instagram",
    icon: "/images/instagram.svg",
    url: "#",
  },
  {
    name: "linkedin",
    icon: "/images/linkedin.svg",
    url: "#",
  },
  {
    name: "facebook",
    icon: "/images/facebook.svg",
    url: "#",
  },
  {
    name: "twitter",
    icon: "/images/twitter.svg",
    url: "#",
  },
  {
    name: "pinterest",
    icon: "/images/pinterest.svg",
    url: "#",
  },
];
const testimonials = [
  {
    id: 1,
    description:
      "Hi, I just wanted to say thanks for the great theme. I’m finding new ways to improve it every day and find that it’s been the most user-friendly theme I’ve ever purchased. I am practically computer illiterate and know extremely little if anything at all about code, CSS, or any of the inner workings of a website. ",
    author: "Justin Moran, Sales Director at Michagan Wood Pellet Fuel",
  },
  {
    id: 2,
    description:
      "If I could give 100 stars for support and attention to detail I definitely would. I’ve purchased a good number of WordPress themes from various developers that look good on Themeforest but after you buy them you find numerous problems, bugs, crazy admin and very very poor support. I purchased the Avada theme and there were a couple of problems too, but the guys from ThemeFusion were remarkable.",
    author: "Dennis Seaman, Pres/Co Founder Sea Yu Enterprises",
  },
];

const promotions = [
  { id: 1, image: "http://picsum.photos/200/200", body: "Save up to $ 300" },
  {
    id: 2,
    image: "http://picsum.photos/200/201",
    body: "Save up to $ 300 with this limited time coupon only available today",
  },
  {
    id: 3,
    image: "http://picsum.photos/201/200",
    body: "Coupon with thumbnail attached",
  },
  {
    id: 4,
    body: "Borring promotion without image",
  },
];

export {
  fakeReviews,
  daysArray,
  fakeList1,
  benefits,
  fakeList2,
  fakeImages,
  mapCoordenates,
  socialMediaIcons,
  testimonials,
  documents,
  promotions,
};
