import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "lazysizes";

const MultiCarousel = ({ items, title, itemNumber, containerExtraClass }) => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 2048 },
      items: Math.ceil(itemNumber * 1.5),
    },
    desktop: {
      breakpoint: { max: 2048, min: 1024 },
      items: itemNumber,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: Math.ceil(itemNumber * 0.5),
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: Math.ceil(itemNumber * 0.25),
    },
  };

  return (
    <div>
      <h4 className="bullseye-red">{title}</h4>
      <Carousel
        responsive={responsive}
        autoPlay={true}
        infinite={false}
        autoPlaySpeed={5000}
        containerClass="carousel-container text-center"
        itemClass="carousel-item"
      >
        {items.map((item) => {
          return (
            <div key={item.id} className={containerExtraClass}>
              <img
                className="lazyload img-fluid"
                data-src={item.image}
                title={item.titleImage}
                alt={item.titleImage}
              />
            </div>
          );
        })}
      </Carousel>
    </div>
  );
};

export default MultiCarousel;
