import React from "react";
import "./googleMap.scss";

const GoogleMapMarker = ({ text }) => {
  return (
    <div className="pin">
      <img src="/images/beIcon.png" alt="bullseye pin" />
      {text && <p>{text}</p>}
    </div>
  );
};

export default GoogleMapMarker;
