import React from "react";
import PropTypes from "prop-types";

const Payment = ({ insurance, paymantOptions }) => {
  return (
    <div>
      {insurance && (
        <>
          <h5>Insurance</h5>
          <p>{insurance}</p>
        </>
      )}
      {paymantOptions && (
        <>
          <h5>Payment Options</h5>
          <p>{paymantOptions}</p>
        </>
      )}
    </div>
  );
};
Payment.propTypes = {
  /** Text description of the ensurance of the location */
  insurance: PropTypes.string,

  /** Text description of the payment options of the location */
  paymantOptions: PropTypes.string,
};

export default Payment;
