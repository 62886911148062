import React from "react";
import "./cardWithImage.scss";

/** simple component to show a list with text body and image to the left
 */
const CardWithImage = ({ title, body, image }) => {
  return (
    <div>
      <h4 className="bullseye-red">{title}</h4>
      <div>
        {image && (
          <img
            className="cardWithImage__img--maxSize m-2 float-left"
            src={image}
            alt={title}
          ></img>
        )}
        <div dangerouslySetInnerHTML={{ __html: body }} />
      </div>
    </div>
  );
};

export default CardWithImage;
