import React from "react";
import StartRating from "./startRating";
import PropTypes from "prop-types";

const CardRating = ({
  author_name,
  relative_time_description,
  rating,
  text,
}) => {
  return (
    <div className="border-bottom border-top">
      <div className="my-3">
        <h5 className="mb-0">
          {author_name}
          <small className="ml-2 text-muted">{relative_time_description}</small>
        </h5>
        <StartRating rating={rating} />
        <p>{text}</p>
      </div>
    </div>
  );
};
CardRating.propTypes = {
  /** Author name of the review */
  author_name: PropTypes.string.isRequired,

  /** description of the date the review was made */
  relative_time_description: PropTypes.string.isRequired,

  /** rating number that was given in the review */
  rating: PropTypes.number.isRequired,
  /** Text description of the review */
  text: PropTypes.string,
};
export default CardRating;
